var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('vue-header',{attrs:{"title":"舌诊","isReturn":"true"}}),_c('face',{attrs:{"active":"2"}}),(_vm.device!==3)?_c('div',[_c('div',{class:{ device: _vm.device==1?true:false }},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"p3"},[_vm._m(2),_vm._m(3),_c('button',{staticClass:"face-photo",on:{"click":_vm.photoFun}},[_vm._v("开始舌诊")]),_vm._m(4),_vm._m(5)]),_c('div',{staticClass:"p4"},[_vm._m(6),_vm._m(7),_c('button',{staticClass:"face-photo trans-photo",on:{"click":_vm.photoFun}},[_vm._v("开始舌诊")]),_vm._m(8),_vm._m(9)])]),(_vm.device!==1)?_c('input-file',{attrs:{"type":_vm.type}}):_vm._e()],1):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"p1"},[_c('div',{staticClass:"step"},[_c('div',{staticClass:"step-mint"},[_c('div',{staticClass:"mint-color"},[_vm._v("您即将进入"),_c('span',[_vm._v("[舌诊：伸舌头]")])])]),_c('div',{staticClass:"step-notice flex"},[_c('div',{staticClass:"flex-1 flex flex-y"},[_c('div',{staticClass:"step-line"})]),_c('div',{staticClass:"notice-text"},[_vm._v("拍摄须知")]),_c('div',{staticClass:"flex-1 flex flex-y"},[_c('div',{staticClass:"step-line"})])]),_c('div',{staticClass:"step-tips flex"},[_c('div',{staticClass:"tips flex-1 flex flex-x"},[_c('div',[_c('img',{attrs:{"src":require("../../../images/photo-alarm1.png")}}),_c('p',[_vm._v("请将舌头尽量平展伸出")])])]),_c('div',{staticClass:"tips flex-1 flex flex-x"},[_c('div',[_c('img',{attrs:{"src":require("../../../images/photo-alarm2.png")}}),_c('p',[_vm._v("请将舌头对准像框正中央")])])])]),_c('div',{staticClass:"tips-alert"},[_vm._v(" 温馨提示：苹果手机，系统版本在13.0时，向右旋转横屏拍照 "),_c('img',{attrs:{"src":require("../../../images/rotate.png")}})]),_c('button',{staticClass:"click-photo"},[_vm._v("点击屏幕拍照")])]),_c('img',{staticClass:"face-demo",attrs:{"src":require("../../../images/tongue-test.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"p2"},[_c('div',{staticClass:"transverse flex flex-x"},[_c('img',{attrs:{"src":require("../../../images/face2.png")}})]),_c('div',{staticClass:"mint-color trans-size"},[_vm._v("您即将进入"),_c('span',[_vm._v("[舌诊]")])]),_c('button',{staticClass:"face-photo trans-photo"},[_vm._v("点击屏幕拍照")]),_c('div',{staticClass:"step-notice flex"},[_c('div',{staticClass:"flex-1 flex flex-y"},[_c('div',{staticClass:"step-line"})]),_c('div',{staticClass:"notice-text"},[_vm._v("拍摄须知")]),_c('div',{staticClass:"flex-1 flex flex-y"},[_c('div',{staticClass:"step-line"})])]),_c('div',{staticClass:"step-tips flex"},[_c('div',{staticClass:"tips flex-1 flex flex-x"},[_c('div',[_c('img',{attrs:{"src":require("../../../images/photo-alarm1.png")}}),_c('p',[_vm._v("请将舌头尽量平展伸出")])])]),_c('div',{staticClass:"tips flex-1 flex flex-x"},[_c('div',[_c('img',{attrs:{"src":require("../../../images/photo-alarm2.png")}}),_c('p',[_vm._v("请将舌头对准像框正中央")])])])]),_c('div',{staticClass:"tips-alert"},[_vm._v(" 温馨提示：苹果手机，系统版本在13.0时，向右旋转横屏拍照 "),_c('img',{attrs:{"src":require("../../../images/rotate.png")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"face-device flex flex-x"},[_c('img',{attrs:{"src":require("../../../images/tongue-device.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mint-color"},[_vm._v("您即将进入"),_c('span',[_vm._v("[舌诊：伸舌头]")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"step-notice flex"},[_c('div',{staticClass:"flex-1 flex flex-y"},[_c('div',{staticClass:"step-line"})]),_c('div',{staticClass:"notice-text"},[_vm._v("拍摄须知")]),_c('div',{staticClass:"flex-1 flex flex-y"},[_c('div',{staticClass:"step-line"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"step-tips flex"},[_c('div',{staticClass:"tips flex-1 flex flex-x"},[_c('div',[_c('img',{attrs:{"src":require("../../../images/photo-alarm1.png")}}),_c('p',[_vm._v("请将舌头尽量平展伸出")])])]),_c('div',{staticClass:"tips flex-1 flex flex-x"},[_c('div',[_c('img',{attrs:{"src":require("../../../images/photo-alarm2.png")}}),_c('p',[_vm._v("请将舌头对准像框正中央")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"transverse flex flex-x"},[_c('img',{attrs:{"src":require("../../../images/face2.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mint-color trans-size"},[_vm._v("您即将进入"),_c('span',[_vm._v("[舌诊：伸舌头]")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"step-notice flex"},[_c('div',{staticClass:"flex-1 flex flex-y"},[_c('div',{staticClass:"step-line"})]),_c('div',{staticClass:"notice-text"},[_vm._v("拍摄须知")]),_c('div',{staticClass:"flex-1 flex flex-y"},[_c('div',{staticClass:"step-line"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"step-tips flex"},[_c('div',{staticClass:"tips flex-1 flex flex-x"},[_c('div',[_c('img',{attrs:{"src":require("../../../images/photo-alarm1.png")}}),_c('p',[_vm._v("请将舌头尽量平展伸出")])])]),_c('div',{staticClass:"tips flex-1 flex flex-x"},[_c('div',[_c('img',{attrs:{"src":require("../../../images/photo-alarm2.png")}}),_c('p',[_vm._v("请将舌头对准像框正中央")])])])])
}]

export { render, staticRenderFns }